export default {
  methods: {
    removeAccent(str) {
      var map = {
        a: "á|à|ã|â|ä",
        e: "é|è|ê|ë",
        i: "í|ì|î|ï",
        o: "ó|ò|ô|õ|ö",
        u: "ú|ù|û|ü",
        c: "ç",
        n: "ñ",
      };
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], "g"), pattern);
      }
      return str;
    },
  },
};
