<template>
  <div
    class="stock-box"
    :class="[isStockOpen ? 'stock-box__open' : 'stock-box__closed']"
  >
    <div class="stock-box__quit d-sm-none" @click="closeStockNav">
      <img alt="cross icon" src="../assets/svg/cross.svg" v-svg-inline />
    </div>
    <div class="stock-box__title-bar">
      <div class="stock-box__blank"></div>
      <h1 class="stock-box__title">My bar</h1>
      <div
        class="stock-box__bin"
        v-if="stockingredients.length !== 0"
        @click="emptyStock"
      >
        <div class="stock-box__bin-tooltip d-flex">
          <img alt="bin icon" src="../assets/svg/bin.svg" v-svg-inline />
          <span class="stock-box__bin-tooltip-text">
            Remove all ingredients
          </span>
        </div>
      </div>
      <div v-else class="stock-box__blank"></div>
    </div>
    <div class="stock-box__placeholder" v-if="stockingredients.length === 0">
      <div class="stock-box__placeholder-icon">
        <img
          alt="ingredients icon"
          src="../assets/svg/ingredients.svg"
          v-svg-inline
        />
      </div>
      <div class="stock-box__placeholder-text">
        Add ingredients to your bar by clicking on them
      </div>
    </div>
    <ul v-else class="stock-box__list">
      <li
        class="stocked-ingredient"
        v-for="ingredient in stockingredients"
        :key="ingredient"
      >
        <div
          class="stocked-ingredient__icon"
          v-html="ingredient.icon_raw_svg"
        ></div>
        <div class="stocked-ingredient__name">{{ ingredient.name }}</div>
        <div
          class="stocked-ingredient__cross-box"
          @click="removeFromStock(ingredient)"
        >
          <img alt="cross icon" src="../assets/svg/cross.svg" v-svg-inline />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StockBox",
  computed: {
    stockingredients() {
      return this.mainStore.stockIngredients;
    },
    isStockOpen() {
      return this.mainStore.isStockOpen;
    },
  },
  methods: {
    emptyStock() {
      this.mainStore.emptyStock();
    },
    removeFromStock(ingredient) {
      this.mainStore.removeFromStock(ingredient);
    },
    closeStockNav() {
      this.mainStore.closeStockNav();
    },
  },
};
</script>
