export default {
  methods: {
    getIngredient(ingredientid) {
      return this.mainStore.ingredients.find(
        (element) => element.id === ingredientid
      );
    },
    getNameString(component) {
      var ingredient = this.getIngredient(component.ingredient);
      if (component.quantity <= 1) {
        if (ingredient.name_singular !== "") {
          return ingredient.name_singular;
        } else {
          return ingredient.name;
        }
      } else {
        return ingredient.name;
      }
    },
    toggleFavorite(recipe) {
      this.mainStore.toggleFavorite(recipe);
    },
    isInFavorite(recipe) {
      return this.mainStore.favoriteList.includes(recipe.id);
    },
  },
};
