export default {
  methods: {
    applyMask(elementclass) {
      var element = document.getElementsByClassName(elementclass).item(0);
      if (element != null) {
        if (element.scrollHeight > element.clientHeight) {
          if (element.scrollTop == 0) {
            element.classList.remove("scroll-bottom");
            element.classList.remove("scroll-middle");
            element.classList.add("scroll-top");
          } else if (
            element.scrollTop >=
            element.scrollHeight - element.clientHeight - 1
          ) {
            element.classList.remove("scroll-middle");
            element.classList.add("scroll-bottom");
          } else {
            element.classList.remove("scroll-top");
            element.classList.remove("scroll-bottom");
            element.classList.add("scroll-middle");
          }
        } else {
          element.classList.remove("scroll-top");
          element.classList.remove("scroll-bottom");
          element.classList.remove("scroll-middle");
        }
      }
    },
  },
};
