<template>
  <div class="results-page">
    <div class="results-page__container">
      <div class="results-page__filters">
        <div class="results-page__search-bar">
          <input
            ref="recipeinput"
            class="results-page__search-bar-input"
            type="text"
            placeholder="Search recipe"
            maxlength="30"
            v-model="recipenameinput"
          />
        </div>
        <div class="d-flex">
          <div class="toggle">
            <input
              class="toggle__checkbox"
              id="togglefavorite"
              type="checkbox"
              v-model="favorite"
              @change="applyMask('results-page__results')"
            />
            <label for="togglefavorite" class="toggle__container">
              <div class="toggle__trail"></div>
              <div class="toggle__handler --heart">
                <img
                  alt="heart icon"
                  src="../assets/svg/heart.svg"
                  v-svg-inline
                />
              </div>
            </label>
          </div>
          <div class="toggle">
            <input
              class="toggle__checkbox"
              id="togglealcohol"
              type="checkbox"
              v-model="alcoholfree"
              @change="applyMask('results-page__results')"
            />
            <label for="togglealcohol" class="toggle__container">
              <div class="toggle__trail"></div>
              <div class="toggle__handler">
                <img
                  alt="no-alcohol icon"
                  src="../assets/svg/no-alcohol.svg"
                  v-svg-inline
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div
        class="results-page__results scroll-top"
        @scroll="applyMask('results-page__results')"
      >
        <div class="results-page__results-section">
          <img alt="line icon" src="../assets/svg/line.svg" v-svg-inline />
          Cocktails you can make
          <img alt="line icon" src="../assets/svg/line.svg" v-svg-inline />
        </div>
        <div
          v-if="matchingrecipes.length === 0"
          class="results-page__place-holder d-flex flex-column"
        >
          <div class="results-page__place-holder-text">
            {{ placeholdertext }}
          </div>
          <router-link to="/" class="results-page__place-holder-button">
            Complete my bar
          </router-link>
        </div>
        <ul v-else class="results-page__results-list">
          <RecipeButton
            v-for="recipe in matchingrecipes"
            :recipe="recipe"
            :key="recipe.id"
          ></RecipeButton>
          <router-link
            v-show="recipenameinput == ''"
            to="/"
            class="recipe-button"
          >
            <div class="recipe-button__image --more">
              <div class="recipe-button__more-icon">
                <img alt="add icon" src="../assets/svg/add.svg" v-svg-inline />
              </div>
            </div>
            <div class="recipe-button__text">
              <div class="recipe-button__name">Want more?</div>
              <div class="recipe-button__ingredients">Add ingredients</div>
            </div>
          </router-link>
        </ul>
        <div v-if="suggestedrecipes.length !== 0">
          <div class="results-page__results-section">
            <img alt="line icon" src="../assets/svg/line.svg" v-svg-inline />
            Almost there
            <img alt="line icon" src="../assets/svg/line.svg" v-svg-inline />
          </div>
          <ul class="results-page__results-list">
            <RecipeButton
              v-for="recipe in suggestedrecipes"
              :recipe="recipe"
              :key="recipe.id"
            ></RecipeButton>
          </ul>
        </div>
      </div>
    </div>
    <StockBox />
  </div>
</template>

<script>
import StockBox from "@/components/StockBox.vue";
import RecipeButton from "@/components/RecipeButton.vue";
import removeAccentMixin from "@/mixins/utils/removeAccent.js";
import applyMaskMixin from "@/mixins/utils/applyMask.js";

export default {
  name: "results-page",
  components: {
    StockBox,
    RecipeButton,
  },
  mixins: [removeAccentMixin, applyMaskMixin],
  data() {
    return { alcoholfree: false, favorite: false, recipenameinput: "" };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      if (window.innerWidth > 576 && window.innerWidth > 450) {
        //focus on input only for desktop
        this.$refs.recipeinput.focus();
      }
    },
  },
  computed: {
    matchingrecipes() {
      return this.mainStore.recipes
        .filter((recipe) => recipe.missing == 0)
        .filter((recipe) => (this.alcoholfree ? !recipe.alcohol : true))
        .filter((recipe) =>
          this.favorite ? this.mainStore.favoriteList.includes(recipe.id) : true
        )
        .filter((recipe) =>
          this.removeAccent(recipe.name.toLowerCase()).includes(
            this.removeAccent(this.recipenameinput.toLowerCase())
          )
        );
    },
    suggestedrecipes() {
      return this.mainStore.recipes
        .filter((recipe) =>
          this.recipenameinput == "" && !this.favorite
            ? [1, 2].includes(recipe.missing) & recipe.oneinstock
            : recipe.missing !== 0
        )
        .filter((recipe) => (this.alcoholfree ? !recipe.alcohol : true))
        .filter((recipe) =>
          this.favorite ? this.mainStore.favoriteList.includes(recipe.id) : true
        )
        .filter((recipe) =>
          this.removeAccent(recipe.name.toLowerCase()).includes(
            this.removeAccent(this.recipenameinput.toLowerCase())
          )
        );
    },
    placeholdertext() {
      if (this.recipenameinput == "") {
        return "Sorry, no matching recipes with the ingredients in your bar.";
      } else {
        return "Sorry, no matching recipes. Complete your bar or change filters.";
      }
    },
  },
  updated() {
    this.applyMask("results-page__results");
  },
};
</script>
