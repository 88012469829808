<template>
  <router-link
    class="recipe-button__link"
    :to="{
      name: 'recipe',
      params: { name: recipe.name.split(' ').join('-'), id: recipe.id },
    }"
    :key="recipe.id"
  >
    <div class="recipe-button">
      <div>
        <div v-if="recipe.missing" class="recipe-button__missing">
          -{{ recipe.missing }}
        </div>
        <img class="recipe-button__image" :src="recipe.image_thumbnail" />
      </div>
      <div class="recipe-button__text">
        <div class="recipe-button__name">{{ recipe.name }}</div>
        <div
          class="recipe-button__ingredients"
          v-html="IngredientListString"
        ></div>
      </div>
      <div
        class="recipe-button__favorite"
        :class="{ 'recipe-button__infavorite': isInFavorite(recipe) }"
        @click.prevent="toggleFavorite(recipe)"
      >
        <img alt="heart icon" src="../assets/svg/heart.svg" v-svg-inline />
      </div>
    </div>
  </router-link>
</template>

<script>
import recipeComponentMixin from "@/mixins/recipeComponent.js";

export default {
  name: "RecipeButton",
  mixins: [recipeComponentMixin],
  props: {
    recipe: {
      required: true,
    },
  },
  computed: {
    IngredientListString() {
      return this.recipe.components
        .map((component) => this.getColoredNameString(component))
        .toString()
        .split(",")
        .join(", ")
        .replace(", Ice cubes", "");
    },
  },
  methods: {
    getColoredNameString(component) {
      if (
        this.mainStore.stockIngredients
          .map((ingredient) => ingredient.id)
          .includes(component.ingredient) ||
        component.optional
      ) {
        return this.getNameString(component);
      } else {
        return (
          "<span class=recipe-button__ingredients-missing>" +
          this.getNameString(component) +
          "</span>"
        );
      }
    },
  },
};
</script>
